<template>
  <v-card
    v-if="project"
    class="border-radius-lg"
    max-height="80vh"
    style="
      width: 350px;
      position: relative;
      display: inline-block;
      display: flex !important;
      flex-direction: column;
      align-items: start;
    "
  >
    <v-card-text class="pa-0 ma-0">
      <v-row class="justify-space-between pt-3 pb-5 px-9" v-if="title">
        <div>
          <h5 class="text-h5 my-2 font-weight-bold text-typo">Aufgaben</h5>
        </div>
      </v-row>
      <v-row class="justify-start align-start pt-3 pb-3 px-3" v-else>
        <v-avatar size="30" class="ma-3 text-uppercase text-dark" color="grey">
          <v-img
            :src="getImage(project.imagePath)"
            :alt="project.serviceProvider.name"
            v-on:error="onImgError"
          />
        </v-avatar>
        <v-col>
          <div>
            <h5 class="text-h5 font-weight-bold text-typo">
              {{ project.title }}
            </h5>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      grow
      class="px-5"
      touchless
    >
      <v-tab :ripple="false" href="#open">
        <span class="ms-1"
          >Offen ({{
            project.tasks.filter((task) => task.status == false).length
          }})
        </span>
      </v-tab>

      <v-tab :ripple="false" href="#done">
        <span class="ms-1"
          >Erledigt ({{
            project.tasks.filter((task) => task.status == true).length
          }})
        </span>
      </v-tab>
    </v-tabs>
    <v-card-text class="pa-0 ma-0" style="overflow: auto">
      <div class="pa-2 px-3 pt-3" style="min-height: 80px">
        <v-window v-model="tab" touchless>
          <v-window-item value="open">
            <draggable
              v-if="selectedType == 'provider'"
              v-model="project.tasks"
              :options="{ handle: '.area' }"
              group="projects"
              :force-fallback="true"
              @start="drag = true"
              @end="
                drag = false;
                updateList();
              "
            >
              <div
                class="bg-light mb-3 font-weight-normal border-radius-lg pa-3"
                v-if="task.status == false"
                v-for="task in project.tasks"
                :key="task.id"
                style="cursor: pointer"
              >
                <v-row class="align-center pa-0 ma-0 justify-space-between">
                  <v-col class="pa-0 ma-0 pr-3" @click="selectTask(task)">
                    <div>
                      <v-card
                        class="mb-1"
                        :style="`backgroundColor: #${
                          getColorByColorCode(task.color).colorCode
                        }`"
                        style="height: 5px; width: 40px"
                      ></v-card>
                      {{ task.title }}
                    </div>
                    <div v-if="task.deadline" class="mt-1">
                      <small style="color: #8f9298 !important">
                        Deadline:
                        {{ task.deadline | moment("DD.MM.YYYY HH:mm") }}
                      </small>
                    </div>
                  </v-col>

                  <v-icon
                    size="20"
                    class="text-secondary area"
                    style="cursor: move"
                    >fas fa-grip-lines</v-icon
                  >
                </v-row>
              </div>
            </draggable>
            <div v-else>
              <div
                class="bg-light mb-3 font-weight-normal border-radius-lg pa-3"
                v-for="task in project.tasks.filter(
                  (task) => task.status == false
                )"
                :key="task.id"
              >
                <v-row class="align-center pa-0 ma-0 justify-space-between">
                  <v-col class="pa-0 ma-0 pr-3">
                    <div>
                      <v-card
                        class="mb-1"
                        :style="`backgroundColor: #${
                          getColorByColorCode(task.color).colorCode
                        }`"
                        style="height: 5px; width: 40px"
                      ></v-card>
                      {{ task.title }}
                    </div>
                    <div v-if="task.deadline" class="mt-1">
                      <small>
                        Deadline:
                        {{ task.deadline | moment("DD.MM.YYYY HH:mm") }}</small
                      >
                    </div>
                  </v-col>
                  <v-icon size="20" class="text-secondary"
                    >fas fa-grip-lines</v-icon
                  >
                </v-row>
              </div>
            </div>
          </v-window-item>
          <v-window-item value="done">
            <draggable
              v-if="selectedType == 'provider'"
              v-model="project.tasks"
              :options="{ handle: '.area' }"
              group="projects"
              @start="drag = true"
              @end="
                drag = false;
                updateList();
              "
            >
              <div
                class="bg-light mb-3 font-weight-normal border-radius-lg pa-3"
                v-if="task.status == true"
                v-for="task in project.tasks"
                :key="task.id"
                style="cursor: pointer"
              >
                <v-row class="align-center pa-0 ma-0 justify-space-between">
                  <v-col class="pa-0 ma-0 pr-3" @click="selectTask(task)">
                    <div>
                      <v-card
                        class="mb-1"
                        :style="`backgroundColor: #${
                          getColorByColorCode(task.color).colorCode
                        }`"
                        style="height: 5px; width: 40px"
                      ></v-card>
                      {{ task.title }}
                    </div>
                    <div v-if="task.deadline" class="mt-1">
                      <small>
                        Deadline:
                        {{ task.deadline | moment("DD.MM.YYYY HH:mm") }}
                      </small>
                    </div>
                  </v-col>
                  <v-icon
                    size="20"
                    class="text-secondary area"
                    style="cursor: move"
                    >fas fa-grip-lines</v-icon
                  >
                </v-row>
              </div>
            </draggable>
            <div v-else>
              <div
                class="bg-light mb-3 font-weight-normal border-radius-lg pa-3"
                v-for="task in project.tasks.filter(
                  (task) => task.status == true
                )"
                :key="task.id"
              >
                <v-row class="align-center pa-0 ma-0 justify-space-between">
                  <v-col class="pa-0 ma-0 pr-3">
                    <div>
                      <v-card
                        class="mb-1"
                        :style="`backgroundColor: #${
                          getColorByColorCode(task.color).colorCode
                        }`"
                        style="height: 5px; width: 40px"
                      ></v-card>
                      {{ task.title }}
                    </div>
                    <div v-if="task.deadline" class="mt-1 text-sm">
                      <small>
                        Deadline:
                        {{ task.deadline | moment("DD.MM.YYYY HH:mm") }}
                      </small>
                    </div>
                  </v-col>
                  <v-icon size="20" class="text-secondary"
                    >fas fa-grip-lines</v-icon
                  >
                </v-row>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </div>
    </v-card-text>
    <div
      class="mx-3"
      style="width: 93%"
      v-if="newTaskActive && selectedType == 'provider'"
    >
      <v-divider></v-divider>
      <v-form @submit.prevent lazy-validation>
        <v-text-field
          placeholder="Neue Aufgabe..."
          class="rounded-lg mt-5 mb-3"
          v-model="newTask"
          filled
          rounded
          hide-details=""
          dense
          @keyup.native.enter="submitTask(project)"
        ></v-text-field>
      </v-form>
    </div>

    <!-- <v-fab-transition>
      <v-btn
        class="bg-default"
        style="bottom: 15px"
        dark
        small
        absolute
        right
        fab
        @click="newTaskActive = !newTaskActive"
      >
        <v-icon style="font-size: 5px !important" class="text-white"
          >fa fa-plus</v-icon
        >
      </v-btn>
    </v-fab-transition> -->
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
  props: {
    project: null,
    title: null,
  },
  components: {
    draggable,
  },
  data: function () {
    return {
      newTaskActive: true,
      tab: null,
      disableDraggable: true,
    };
  },
  methods: {
    submit() {
      if (this.newTask.length) {
        this.newTask = "";
      }
    },
    updateList() {
      for (let index = 0; index < this.project.tasks.length; index++) {
        let element = this.project.tasks[index];
        element.sortOrder = index;
        element.companyId = this.selectedCompany.id;
        this.$store.dispatch("updateTask", element);
      }
      this.$store.dispatch("getProjects", this.selectedCompany.id);
    },
  },
};
</script>

<style>
</style>