<template>
  <div>
    <div
      style="overflow-x: scroll; overflow-y: hidden; white-space: nowrap"
      v-if="projects.data && projects.data.length"
    >
      <div
        v-for="project in projects.data"
        :key="project.id"
        style="position: relative; display: inline-block; vertical-align: top"
      >
        <Tasks class="ma-3" :project="project" />
      </div>
    </div>
    <v-row class="pa-0 ma-0 mx-2" v-else>
      <v-col cols="12" md="4"
        ><v-card class="border-radius-lg mt-5">
          <v-row class="justify-space-between pt-3 pb-5 px-7">
            <div>
              <h5 class="text-h5 my-2 font-weight-bold text-typo">Aufgaben</h5>
            </div>
          </v-row>
          <v-divider></v-divider>

          <div class="pa-5">
            <div>Du hast noch keine Projekte, jetzt hinzufügen?</div>

            <v-btn
              @click="openNewProjectModal()"
              :elevation="0"
              color="#cb0c9f"
              class="font-weight-bolder btn-dark bg-light py-4 px-4 mt-5"
              small
            >
              Projekt hinzufügen
            </v-btn>
          </div>
        </v-card></v-col
      ></v-row
    >
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Tasks from "../../components/Cards/Tasks.vue";
export default {
  name: "TaskOverview",
  components: {
    draggable,
    Tasks,
  },
  data: function () {
    return {};
  },
};
</script>
